import packageDetails from './kewl/packageDetails';

export default (() => {
  let pack = 'liveme';
  switch (packageDetails.packageInfo) {
    case 'joyu': // joyu包
      pack = 'highlive';
      break;
    case 'sparkher': // sparkher包
      pack = 'highlive';
      break;
    case 'arab': // 中东包
      pack = 'ar';
      break;
    case 'us': // 美包
      pack = 'us';
      break;
    case 'livemeet': // 美国包 改为 highlive包的 ios 分包
      // pack = 'livemeet';
      pack = 'highlive';
      break;
    case 'cheez': // cheez包
      pack = 'cheez';
      break;
    case 'lenovo': // lenovo包
      pack = 'lenovo';
      break;
    case 'meet': // meet包
      pack = 'meet';
      break;
    case 'pro': // pro包
      pack = 'lmpro';
      break;
    case 'athena': // athena包
      pack = 'athena';
      break;
    case 'emolm': // emolm包 liveme+
      pack = 'emolm';
      break;
    case 'highlive': // highlive包
      pack = 'highlive';
      break;
    case 'lmtlive': // lmtlive
      pack = 'lmtlive';
      break;
    default: // liveme主包
      pack = 'liveme';
      break;
  }
  return pack;
})();
